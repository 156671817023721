import React from 'react';
import { Box, Stack, Typography } from 'design-system';

interface TripBookedSectionProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  content?: React.ReactNode;
}

export const TripBookedSection: React.FC<TripBookedSectionProps> = ({
  icon,
  title,
  description,
  content,
}) => {
  return (
    <Stack gap={2} direction="row">
      {icon && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 24,
            height: 24,
          }}
        >
          {icon}
        </Box>
      )}
      <Stack gap={1}>
        <Typography variant="Body L bold" lineHeight="20px">
          {title}
        </Typography>
        <Typography variant="Body M" lineHeight="28.8px">
          {description}
        </Typography>
        {content}
      </Stack>
    </Stack>
  );
};
