import { Basket, CustomerUser, OrderDetails } from '@flashpack/graphql';

window.dataLayer = window.dataLayer || [];

export const trackUserId = (userId: string | undefined) => {
  window.dataLayer.push({
    user_id: userId,
  });
};

export const trackCheckoutSuccess = (
  basket: Basket,
  orderDetails: OrderDetails,
  customerUser: CustomerUser,
  checkoutVersion?: string,
) => {
  window.dataLayer.push({
    event: 'transaction_success',
    email: customerUser?.email,
    user_id: customerUser?.id,
    checkout_version: checkoutVersion,
    ecommerce: {
      eventId: basket?.id,
      currencyCode: basket?.currency,
      purchase: {
        actionField: {
          id: basket?.beBookingId,
          revenue: basket?.tripTotal,
          tax: '',
        },
        products: [
          {
            ...createTripProduct(orderDetails),
            dimension2: 'trip',
            dimension3: basket?.appliedPromocodes?.[0] || 'none',
            coupon: basket?.appliedPromocodes?.[0] || 'undefined',
          },
          ...(basket?.extras
            ? basket.extras.map((extra) => ({
                name: extra.name,
                id: extra.id,
                price: extra.price,
                category: orderDetails?.tripRegion,
                quantity: 1,
                dimension2: 'extra',
                dimension3: basket.appliedPromocodes?.[0] || 'none',
                coupon: basket.appliedPromocodes?.[0] || 'undefined',
              }))
            : []),
        ],
      },
    },
  });
};

export const trackCheckoutEvent = (orderDetails: OrderDetails, currency: string) => {
  window.dataLayer.push({
    ecommerce: {
      eventId: `${orderDetails?.tripCode}${currency}`,
      currencyCode: currency?.toUpperCase(),
      checkout: {
        products: [createTripProduct(orderDetails)],
      },
    },
  });
};

export const trackViewRegistrationForm = (
  formViewId: string,
  checkoutVersion: string,
  isPartOfBookingFlow: boolean,
) => {
  window.dataLayer.push({
    event: 'view_registration_form',
    checkout_version: checkoutVersion,
    formViewId,
    isPartOfBookingFlow,
  });
};

export const trackSubmitRegistrationForm = (
  formViewId: string,
  checkoutVersion: string,
) => {
  window.dataLayer.push({
    event: 'submit_registration_form',
    checkout_version: checkoutVersion,
    formViewId,
  });
};

export const trackCheckoutEventStep1 = (
  basket: Basket,
  orderDetails: OrderDetails,
  customerUser: CustomerUser,
) => {
  window.dataLayer.push({
    event: 'checkout',
    email: customerUser?.email,
    user_id: customerUser?.id,
    ecommerce: {
      eventId: basket?.id,
      currencyCode: basket?.currency,
      checkout: {
        actionField: {
          step: 1,
        },
        products: [createTripProduct(orderDetails)],
      },
    },
  });
};

const createTripProduct = (orderDetails?: OrderDetails | null) => {
  return {
    name: orderDetails?.tripName,
    id: orderDetails?.tripCode,
    trip_id: orderDetails?.tourCode,
    price: orderDetails?.tripPrice,
    region: orderDetails?.tripRegion,
    quantity: 1,
  };
};
