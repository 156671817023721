import Layout from '@src/shared/Layout/Layout';
import BookDepartureHeader from '@src/shared/book-departure-header/BookDepartureHeader';
import { Box, Grid, Stack, TickIcon, Typography, useTheme } from 'design-system';
import React, { useMemo } from 'react';
import { useOrderDetails } from '@src/shared/hooks';
import { PaidOrderDetails } from '@src/pay/PaidOrderDetails';
import { DownloadFlashPackApp } from '@src/v3/checkout-sections/your-trip-is-booked/DownloadFlashPackApp';

export const PaymentSuccessPage: React.FC = () => {
  const { data: orderDetailsData, loading: orderDetailsLoading } = useOrderDetails();
  const theme = useTheme();

  const tripLocationLabel = useMemo(() => {
    return orderDetailsData?.orderDetails.location
      ? `to ${orderDetailsData.orderDetails.location}`
      : '';
  }, [orderDetailsData]);

  return (
    <Layout
      HeroComponent={
        <BookDepartureHeader title={'Your trip is booked!'} textAlign="center" />
      }
    >
      <Stack gap={4}>
        <Stack alignItems={'center'} gap={2}>
          <TickIcon
            sx={{
              width: {
                xs: '70px',
                sm: '98px',
              },
              height: {
                xs: '70px',
                sm: '98px',
              },
            }}
          />
          <Stack gap={1}>
            <Typography
              data-testid="payment-complete-message"
              textAlign="center"
              variant="H4"
              typography={{
                xs: 'H5',
                sm: 'H4',
              }}
            >
              Payment complete
            </Typography>
            <Typography
              textAlign="center"
              variant="Body L"
              typography={{
                xs: 'Body S',
                sm: 'Body L',
              }}
              maxWidth={729}
            >
              Your Adventure {tripLocationLabel} is booked. Your confirmation email is on
              its way with the exciting next steps.
            </Typography>
          </Stack>
        </Stack>
        <Grid
          container
          spacing={{ xs: 1, md: 4 }}
          sx={{ display: 'flex', alignItems: 'stretch' }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              order: { xs: 2, md: 1 },
            }}
          >
            <PaidOrderDetails
              orderDetails={orderDetailsData?.orderDetails}
              loading={orderDetailsLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.principal.grey30,
                borderRadius: '25px',
                p: 4,
                flexGrow: 1, // This ensures the Box expands to fill available height
              }}
            >
              <DownloadFlashPackApp displayIcon={false} />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Layout>
  );
};
