import * as React from 'react';
import { Box, FormDropdown, FormTextInput, Validator } from 'design-system';

export const howDidYouHearAboutUsOptions = [
  { label: 'An Ad on Facebook or Instagram', value: 'An Ad on Facebook or Instagram' },
  { label: "Radha's post on LinkedIn", value: "Radha's post on LinkedIn" },
  { label: 'The Friendship Bus', value: 'The Friendship Bus' },
  { label: 'News article', value: 'News article' },
  { label: 'Read a Flash Pack article', value: 'Read a Flash Pack article' },
  { label: 'Podcast', value: 'Podcast' },
  { label: 'Google search', value: 'Google search' },
  { label: 'AI search / ChatGPT', value: 'AI search / ChatGPT' },
  { label: 'An Ad on TikTok', value: 'An Ad on TikTok' },
  { label: "Someone else's social media", value: "Someone else's social media" },
  {
    label: 'A post from Flash Pack on social media',
    value: "Flash Pack's Social media",
  },
  {
    label: 'A friend or family member told me',
    value: 'A friend or family member told me',
  },
  { label: 'Another website', value: 'Another website' },
  { label: 'Other', value: 'Other' },
];

type HowDidYouHearAboutUsProps = {
  isOther: boolean;
};

export const HowDidYouHearAboutUs: React.FC<HowDidYouHearAboutUsProps> = (props) => {
  const { isOther } = props;

  return (
    <Box>
      <FormDropdown
        name="referrer"
        options={howDidYouHearAboutUsOptions}
        validate={Validator.required}
        TextInputProps={{
          size: 'large',
          label: 'How did you hear about us?',
          placeholder: 'Select...',
          fullWidth: true,
          testid: 'create-account-how-did-you-hear-about-us',
        }}
      />
      {isOther && (
        <Box mt={2}>
          <FormTextInput
            name="referrerOther"
            textInputProps={{
              placeholder: 'Tell us how you heard about us...',
              label: 'Please specify',
              testid: 'create-account-referrer-other',
              size: 'large',
              fullWidth: true,
              multiline: true,
              minRows: 2,
            }}
          />
        </Box>
      )}
    </Box>
  );
};
