import React, { useMemo } from 'react';
import { FC, ReactNode } from 'react';
import {
  useTheme,
  Typography,
  SharedAccommodationInclusionIcon,
  EquipmentInclusionIcon,
  GuideInclusionIcon,
  LocalTransportInclusionIcon,
  MostMealsIncludedInclusionIcon,
  MaxTravelersPerGroupInclusionIcon,
  ExclusivelyForAgeRangesInclusionIcon,
  PrivateAirportTransferInclusionIcon,
  CarbonFootprintInclusionIcon,
  LocalGuideTipsSvgInclusionIcon,
  GenericError,
  Box,
  Stack,
  Skeleton,
  WarningMessage,
} from 'design-system';

import { OrderDetails as OrderDetailsType, ProductLineSlug } from '@flashpack/graphql';
import { formatDateToOrdinalMonthYear } from '../dateUtils';

export type OrderDetailsPropType = Pick<
  OrderDetailsType,
  | 'tripName'
  | 'location'
  | 'startDate'
  | 'endDate'
  | 'duration'
  | 'description'
  | 'tripNote'
  | 'tourNote'
  | 'productLine'
  | 'promotionalDiscount'
> & {
  tourInclusions: Pick<
    OrderDetailsType['tourInclusions'][0],
    'id' | 'name' | 'icon' | 'customName'
  >[];
};

export type OrderDetailsPropTypes = {
  orderDetails?: OrderDetailsPropType;
  loading: boolean;
  isFlashPerk?: boolean;
};

export const OrderDetails: React.FC<OrderDetailsPropTypes> = (props) => {
  const { orderDetails, loading, isFlashPerk } = props;
  const theme = useTheme();
  const dateLabels = useMemo(() => {
    if (!orderDetails || !orderDetails.startDate || !orderDetails.endDate) {
      return { startDate: '', endDate: '' };
    }
    const { startDate, endDate } = orderDetails;

    const start = formatDateToOrdinalMonthYear(startDate);
    const end = formatDateToOrdinalMonthYear(endDate);
    return {
      startDate: start,
      endDate: end,
    };
  }, [orderDetails]);

  if (loading) {
    return <Skeleton variant="rectangular" height={547} sx={{ borderRadius: 6 }} />;
  }

  if (!orderDetails) {
    return (
      <GenericError
        error={
          'Something went wrong while fetching your order details. Please try again later.'
        }
      />
    );
  }

  const { tripName, description, duration, tourInclusions, tripNote, tourNote } =
    orderDetails;

  const promotionalDiscountNote =
    "This adventure is part of our 'Flash Perks'— a one-off discount available on any trip at any time. See full T&Cs for more.";

  const notesPresent = Boolean(tripNote?.content || tourNote?.content || isFlashPerk);

  return (
    <OrderCard>
      <Typography variant="H3" mb={3}>
        Order details
      </Typography>
      <Typography variant="H5" mb={0.5}>
        {tripName}
        {orderDetails.productLine === ProductLineSlug.FlashPack && ': Ages 30-49'}
      </Typography>
      <Typography variant="Body M" mb={1}>
        {description}
      </Typography>
      <Stack direction="row" gap={1} mb={1} alignItems={'center'}>
        <DateChip date={dateLabels.startDate} />
        <Typography variant="Body M bold">–</Typography>
        <DateChip date={dateLabels.endDate} />
      </Stack>
      <Typography variant="Body M" mb={3}>
        {duration}
      </Typography>
      <Typography variant="H5" mb={1}>
        Package includes
      </Typography>

      {tourInclusions?.map((i) => {
        return <TourInclusion key={i.id} name={i.customName || i.name} icon={i.icon} />;
      })}
      {notesPresent && (
        <WarningMessage
          iconType={'INFO'}
          message={
            [
              tourNote?.content,
              tripNote?.content,
              isFlashPerk && promotionalDiscountNote,
            ].filter(Boolean) as string[]
          }
          containerSx={{ mt: 3, mb: 0 }}
          alertSx={{ py: 0, px: 1, backgroundColor: theme.palette.system.amber50 }}
        />
      )}
    </OrderCard>
  );
};

export const OrderCard: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.principal.grey30,
        borderRadius: '25px',
        p: 4,
        flexGrow: 1,
      }}
    >
      {children}
    </Box>
  );
};

const DateChip: FC<{ date: string }> = ({ date }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.principal.white,
        padding: '5px 10px',
        borderRadius: '30px',
      }}
    >
      <Typography
        variant="Body M bold"
        typography={{ sm: 'Body S Bold', md: 'Body M Bold' }}
      >
        {date}
      </Typography>
    </Box>
  );
};

export const TourInclusion: FC<{ name: string; icon: string }> = ({ name, icon }) => {
  const getIcon = () => {
    switch (icon) {
      case 'accommodation':
        return <SharedAccommodationInclusionIcon />;
      case 'equipment':
        return <EquipmentInclusionIcon />;
      case 'guide':
        return <GuideInclusionIcon />;
      case 'transport':
        return <LocalTransportInclusionIcon />;
      case 'meal':
        return <MostMealsIncludedInclusionIcon />;
      case 'backpack':
        return <MaxTravelersPerGroupInclusionIcon />;
      case 'age-range':
        return <ExclusivelyForAgeRangesInclusionIcon />;
      case 'trans':
        return <PrivateAirportTransferInclusionIcon />;
      case 'carbon-footprint':
        return <CarbonFootprintInclusionIcon />;
      case 'local-guide-tips':
        return <LocalGuideTipsSvgInclusionIcon />;
    }
  };
  return (
    <Stack direction="row" gap={1} mb={1}>
      {getIcon()}
      <Typography ml={1} variant="Body S">
        {name}
      </Typography>
    </Stack>
  );
};
