import { useState } from 'react';
import { Form } from 'react-final-form';
import {
  FormTextInput,
  LoadingButton,
  Validator,
  composeValidators,
} from 'design-system';
import { Stack, Typography } from '@mui/material';
import Layout from '@src/shared/Layout/Layout';
import { LocationState, useRouting } from '@src/shared/hooks';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { Navigate, useLocation } from 'react-router-dom';
import {
  CreateFirebaseUserFromLegacyAccountDocument,
  RequestPasswordResetEmailDocument,
} from '@flashpack/graphql';
import { useMutation } from '@apollo/client';
import { useAuthentication } from '@src/auth/useAuthentication';

type ResetPasswordForm = {
  email: string;
};

export const ResetPasswordPage = () => {
  const [emailSent, setEmailSent] = useState(false);
  const { authenticated } = useAuthentication();
  const [requestPasswordResetEmail] = useMutation(RequestPasswordResetEmailDocument);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const [createFirebaseUserFromLegacyAccount] = useMutation(
    CreateFirebaseUserFromLegacyAccountDocument,
  );

  const { queryParams } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();

  const isPartOfBookingFlow = Boolean(
    queryParams.currencyCode && queryParams.departureCode,
  );

  const onSubmit = async ({ email }: ResetPasswordForm) => {
    try {
      const redirectBack = isPartOfBookingFlow
        ? CheckoutRoutePath.BOOK_DEPARTURE.value + location.search
        : CheckoutRoutePath.MY_ACCOUNT_DETAILS.value;

      // see if the user exists in the old checkout users table
      // if they do, create a new firebase user for them with random password
      // if they don't, send them a message saying account doesnt exist
      const { data } = await createFirebaseUserFromLegacyAccount({
        variables: {
          input: {
            email,
          },
        },
      });

      if (data?.createFirebaseUserFromLegacyAccount?.success) {
        await requestPasswordResetEmail({
          variables: {
            input: {
              email,
              redirectUrl: redirectBack,
            },
          },
        });
        setEmailSent(true);
      } else {
        // to avoid people using this to email farm, we show the email sent message
        setEmailSent(true);
      }
    } catch (e) {
      setError('Failed to send password reset email. Please try again.');
    }
  };

  if (emailSent) {
    return (
      <Layout>
        <Stack gap={3} minHeight="50vh" mt={2}>
          <Typography variant="H3">Check your email</Typography>
          <Typography variant="Body M">
            If an account exists with that email, we&apos;ve sent a password reset link to
            your email address. Please click the link to reset your password.
          </Typography>
          <Typography variant="Body M">
            If you don&apos;t see the email, please check your spam folder.
          </Typography>
        </Stack>
      </Layout>
    );
  }

  if (authenticated) {
    const defaultRedirectBack = isPartOfBookingFlow
      ? CheckoutRoutePath.BOOK_DEPARTURE.value + location.search
      : CheckoutRoutePath.MY_ACCOUNT_DETAILS.value;

    const redirectBack =
      (location?.state as LocationState)?.redirectBack ?? defaultRedirectBack;
    return <Navigate to={redirectBack} />;
  }

  return (
    <Layout>
      <Stack gap={3} minHeight="50vh" mt={2}>
        <Typography variant="H2">Reset your password</Typography>
        <Typography variant="Body M">
          Enter your email address and we&apos;ll send you a link to reset your password.
        </Typography>

        <Form<ResetPasswordForm>
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={(e) => {
                void handleSubmit();
                e?.preventDefault();
              }}
            >
              <Stack gap={2}>
                <FormTextInput
                  name="email"
                  validateFields={[]}
                  textInputProps={{
                    placeholder: 'Enter email address...',
                    label: 'Email Address',
                    testid: 'reset-password-email',
                    size: 'large',
                    autoComplete: 'email',
                  }}
                  validate={composeValidators(Validator.required, Validator.validEmail)}
                />
                {error && <Typography color="error">{error}</Typography>}
                <LoadingButton
                  loading={submitting}
                  disabled={submitting}
                  type="submit"
                  variant="contained"
                  data-testid="reset-password-button"
                  sx={{ width: '200px' }}
                >
                  {submitting ? 'Sending...' : 'Send Reset Link'}
                </LoadingButton>
              </Stack>
            </form>
          )}
        />
      </Stack>
    </Layout>
  );
};
