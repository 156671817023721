import { createContext, FC, ReactNode, useContext, useEffect } from 'react';

import { useAuthentication } from '@src/auth/useAuthentication';
import {
  CurrentCustomerUserDocument,
  CurrentCustomerUserFieldsFragment,
} from '@flashpack/graphql';
import { useQuery } from '@apollo/client';
import { trackUserId } from '@src/gtm/tracking';

type AuthorizationContextType = {
  currentUser?: CurrentCustomerUserFieldsFragment;
  loadingAuthorization: boolean;
};

export const AuthorizationContext = createContext<AuthorizationContextType>({
  currentUser: undefined,
  loadingAuthorization: false,
});

export const useCustomerAuthorization = () => {
  return useContext(AuthorizationContext);
};

export const AuthorizationProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const {
    currentUser: firebaseUser,
    authenticated,
    loadingAuthentication,
  } = useAuthentication();

  const { data, loading: loadingCurrentUser } = useQuery(CurrentCustomerUserDocument, {
    skip: !authenticated,
  });

  useEffect(() => {
    if (loadingAuthentication) {
      return;
    }
    if (firebaseUser) {
      trackUserId(firebaseUser?.uid);
    } else {
      trackUserId(undefined);
    }
  }, [firebaseUser, authenticated, loadingAuthentication]);

  const currentUser = data?.currentCustomerUser;

  return (
    <AuthorizationContext.Provider
      value={{
        currentUser: currentUser || undefined,
        loadingAuthorization: loadingCurrentUser || loadingAuthentication,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
