import {
  OrderDetailsPropType,
  TourInclusion,
} from '@src/shared/order-details/OrderDetails';
import { formatDateToOrdinalMonthYear } from '@src/shared/dateUtils';
import { OrderCard } from '@src/shared/order-details/OrderDetails';
import { GenericError, Skeleton, Stack, Typography } from 'design-system';
import { FC, useMemo } from 'react';

type PaidOrderDetailsProps = {
  orderDetails?: OrderDetailsPropType;
  loading: boolean;
};

export const PaidOrderDetails: FC<PaidOrderDetailsProps> = (props) => {
  const { orderDetails, loading } = props;

  const dateLabels = useMemo(() => {
    if (!orderDetails || !orderDetails.startDate || !orderDetails.endDate) {
      return { startDate: '', endDate: '' };
    }
    const { startDate, endDate } = orderDetails;

    const start = formatDateToOrdinalMonthYear(startDate);
    const end = formatDateToOrdinalMonthYear(endDate);
    return {
      startDate: start,
      endDate: end,
    };
  }, [orderDetails]);

  if (loading) {
    return <Skeleton variant="rectangular" height={547} sx={{ borderRadius: 6 }} />;
  }
  if (!orderDetails) {
    return (
      <GenericError
        error={
          'Something went wrong while fetching your order details. Please try again later.'
        }
      />
    );
  }

  const { tripName, duration, tourInclusions } = orderDetails;

  return (
    <OrderCard>
      <Typography variant="H5" mb={0.5}>
        {tripName}
      </Typography>
      <Typography variant="Body M" mb={1}>
        {duration}
      </Typography>
      <Stack direction="row" gap={1} mb={3} alignItems={'center'}>
        <Typography variant="Body M bold">
          {dateLabels.startDate} – {dateLabels.endDate}
        </Typography>
      </Stack>
      {tourInclusions && (
        <Typography variant="H5" mb={1}>
          Package includes
        </Typography>
      )}

      {tourInclusions?.map((i) => {
        return <TourInclusion key={i.id} name={i.customName || i.name} icon={i.icon} />;
      })}
    </OrderCard>
  );
};
