import { FirebaseApp, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_AUTH_API_KEY,
  projectId: import.meta.env.VITE_FIREBASE_AUTH_PROJECT_ID,
  authDomain: 'flashpack.com',
};

const shouldUseEmulator = import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST;

export let firebaseApp: FirebaseApp;

export const initializeFirebase = () => {
  firebaseApp = initializeApp(firebaseConfig);

  if (shouldUseEmulator) {
    const emulatorHost = import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST;
    connectAuthEmulator(getAuth(), emulatorHost, {
      disableWarnings: true,
    });
    const url = new URL(emulatorHost);
    connectStorageEmulator(
      getStorage(),
      url.hostname,
      import.meta.env.VITE_FIREBASE_STORAGE_EMULATOR_PORT as number,
    );
  }
};
