import { CurrentCustomerDetailsDocument } from '@flashpack/graphql';
import { useQuery } from '@apollo/client';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuthentication } from '@src/auth/useAuthentication';

export const CustomerDetailsRequiredRoute = ({
  children,
  navigateTo = 'register',
}: {
  children?: JSX.Element;
  navigateTo?: string;
}) => {
  const { authenticated, loadingAuthentication } = useAuthentication();

  const { data: currentCustomerDetailsData, loading: loadingCurrentCustomerDetails } =
    useQuery(CurrentCustomerDetailsDocument, {
      skip: !authenticated,
    });

  if (loadingAuthentication || loadingCurrentCustomerDetails) {
    return null;
  }

  if (!authenticated) {
    return (
      <Navigate
        to={navigateTo + location.search}
        state={{
          redirectBack: location.pathname + location.search,
        }}
        replace
      />
    );
  }

  if (!currentCustomerDetailsData?.currentCustomerDetails) {
    return (
      <Navigate
        to={'register' + location.search}
        state={{
          redirectBack: location.pathname + location.search,
        }}
        replace
      />
    );
  }

  return children ? children : <Outlet />;
};
