import { useMutation, useQuery } from '@apollo/client';
import { Field, Form, FormSpy } from 'react-final-form';
import {
  CreatePaymentIntentDocument,
  CurrentCustomerDetailsDocument,
  CustomerCurrency,
  PaymentOptionType,
  ProductLineSlug,
  UpdateBasketDocument,
  UpdateCustomerBillingDetailsDocument,
} from '@flashpack/graphql';
import {
  Box,
  composeValidators,
  createPrioritizedCountryOptions,
  Divider,
  FormDropdown,
  FormTextInput,
  GenericError,
  Stack,
  Typography,
  Validator,
  ValidatorFactory,
} from 'design-system';
import * as Sentry from '@sentry/react';
import BookDepartureHeader from '@src/shared/book-departure-header/BookDepartureHeader';
import ReservationTimer from './reservation-timer/ReservationTimer';
import Layout from '@src/shared/Layout/Layout';
import StepperPill from '@src/shared/stepper-pill/StepperPill';
import { LoadingButton } from 'design-system';
import { JoinWhatsAppFormField } from '@src/shared/join-whatsapp/JoinWhatsAppFormField';
import ToPayWidget from '@src/pay/ToPayWidget';
import { SpeakToExpert } from '@src/shared/support-chat/SpeakToExpert';
import PromotionalCode from './promotional-code/PromotionalCode';
import {
  useBasket,
  useExpiredReservationRedirect,
  useRouting,
  useOutOfAgeRangeRedirect,
} from '@src/shared/hooks';
import { Extras } from '@src/shared/extras/Extras';
import { useOrderDetails } from '@src/shared/hooks/useOrderDetails';
import { OrderDetails } from '@src/shared/order-details/OrderDetails';
import { PageFormTitle } from '@src/shared/page-form-title/PageFormTitle';
import { ResponsiveSideBySide } from '@src/shared/responsive-side-by-side/ResponsiveSideBySide';
import { PageFormLoader } from '@src/shared/page-form-loader/PageFormLoader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { trackCheckoutEventStep1, trackCheckoutSuccess } from '@src/gtm/tracking';
import { useCustomerAuthorization } from '@src/auth/AuthorizationProvider';
import { PaymentOptions } from '@src/book-departure/payment-options/PaymentOptions';
import { BookingTermsAndConditionsFormField } from '@src/shared/booking-terms-and-conditions/BookingTermsAndConditionsFormField';
import {
  AirwallexDropInElement,
  CreatedPaymentIntent,
} from '@src/airwallex/AirwallexDropIn';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { TotalToPay } from '@src/pay/TotalToPay';
import { format } from 'date-fns';
import { QuoteCard } from '@src/shared/RoomHesitation/QuoteCard';
import melissaCapocciImage from '@src/shared/RoomHesitation/melissa-capocci.png';
import { AgeSweetspotBanner } from '@src/shared/age-sweetspot-banner/AgeSweetspotBanner';
import { triggerConvertConversion } from '@src/shared/utils/trackingUtils';

export type BookDepartureFormValues = {
  joinWhatsAppGroup: boolean;
  acceptedTermsAndConditions: boolean;
  // we use plain form values structure in forms to avoid TS errors
  selectedPaymentOptionType: string;
  selectedPaymentOptionDepositAmount: number | null;
  selectedPaymentOptionStartDate: string | null;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postcode: string;
};

const prioritizedCountryOptions = createPrioritizedCountryOptions(['us', 'gb']).map(
  (option) => ({
    label: option.label,
    value: option.value.toUpperCase(),
  }),
);

export const BookDeparturePage = () => {
  const [createPaymentIntent] = useMutation(CreatePaymentIntentDocument);
  const { queryParams, navigate } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();
  const { departureCode, currencyCode } = queryParams;
  const { data: customerDetailsData, loading: customerDetailsLoading } = useQuery(
    CurrentCustomerDetailsDocument,
  );
  const {
    loading: loadingBasket,
    error,
    basket,
  } = useBasket({ autoRefreshAllowed: true });
  const [isAirwallexDropInOpen, setIsAirwallexDropInOpen] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [amountToPay, setAmountToPay] = useState<number | null>(null);
  const { currentUser } = useCustomerAuthorization();
  const [updateBillingDetails] = useMutation(UpdateCustomerBillingDetailsDocument);
  const [updateBasket] = useMutation(UpdateBasketDocument);

  const { data: orderDetailsData, loading: orderDetailsLoading } = useOrderDetails();

  const customerCurrency = currencyCode.toLowerCase() as CustomerCurrency;
  const termsAndConditions = basket?.terms?.content;
  const userHasBookingOnThisTour = basket?.userHasBooking;

  useEffect(() => {
    if (userHasBookingOnThisTour) {
      navigate(CheckoutRoutePath.MY_TRIPS.value, {
        preserveExisting: true,
      });
    }
  }, [userHasBookingOnThisTour, navigate]);

  useExpiredReservationRedirect({
    skip: loadingBasket || !basket?.reservation,
    reservationExpiresIn: basket?.reservation?.expiresIn,
    reservationExpired: !!basket?.reservation?.expired,
  });

  const { isInAgeSweetspot } = useOutOfAgeRangeRedirect({
    skip: loadingBasket || !basket?.reservation,
    productLineSlug: orderDetailsData?.orderDetails.productLine,
    userDateOfBirth: customerDetailsData?.currentCustomerDetails?.dateOfBirth,
  });

  const handleAirwallexSuccess = useCallback(() => {
    triggerConvertConversion();
    if (basket && orderDetailsData?.orderDetails && currentUser) {
      trackCheckoutSuccess(basket, orderDetailsData.orderDetails, currentUser, '2');
    }
    navigate(CheckoutRoutePath.PAYMENT_SUCCESS.value, {
      preserveExisting: true,
    });
  }, [navigate, basket, orderDetailsData?.orderDetails, currentUser]);

  const handleAirwallexError = useCallback((error: unknown) => {
    Sentry.captureException('Airwallex payment failed', {
      level: 'warning',
      extra: { error: JSON.stringify(error) },
    });
    setPaymentError(true);
  }, []);

  useEffect(() => {
    if (
      !loadingBasket &&
      !orderDetailsLoading &&
      currentUser &&
      basket &&
      orderDetailsData?.orderDetails
    ) {
      trackCheckoutEventStep1(basket, orderDetailsData?.orderDetails, currentUser);
    }
  }, [
    loadingBasket,
    orderDetailsLoading,
    basket,
    orderDetailsData?.orderDetails,
    currentUser,
  ]);

  const initialValues = useMemo(() => {
    const paymentOptionsAvailable = basket?.availablePaymentOptions?.length !== 0;

    const selectedPaymentOptionIsAvailable =
      paymentOptionsAvailable &&
      basket?.availablePaymentOptions.find(
        (option) => option.type === basket?.selectedPaymentOption?.optionType,
      );

    const selectedPaymentOptionType = selectedPaymentOptionIsAvailable
      ? basket?.selectedPaymentOption?.optionType
      : PaymentOptionType.FullBalance;

    const paymentStartDate = basket?.selectedPaymentOption?.paymentPlan?.startDate;
    const paymentPlanStartDateFormatted = paymentStartDate
      ? format(new Date(paymentStartDate), 'yyyy-MM-dd')
      : null;

    return {
      addressLine1: customerDetailsData?.currentCustomerDetails?.address?.addressLine1,
      addressLine2:
        customerDetailsData?.currentCustomerDetails?.address?.addressLine2 ?? undefined,
      city: customerDetailsData?.currentCustomerDetails?.address?.city,
      country: customerDetailsData?.currentCustomerDetails?.address?.countryCode,
      postcode: customerDetailsData?.currentCustomerDetails?.address?.postCode,
      joinWhatsAppGroup: basket?.joinWhatsAppGroup,
      selectedPaymentOptionType,
      selectedPaymentOptionDepositAmount:
        basket?.selectedPaymentOption?.depositAmount || basket?.tourDeposit,
      selectedPaymentOptionStartDate: paymentPlanStartDateFormatted,
    };
  }, [basket, customerDetailsData]);

  if (loadingBasket || customerDetailsLoading) {
    return (
      <Layout
        HeroComponent={
          <BookDepartureHeader location={orderDetailsData?.orderDetails.location} />
        }
      >
        <PageFormLoader />
      </Layout>
    );
  }

  if (error || !basket) {
    return (
      <GenericError
        error={'Something went wrong while creating your order. Please try again later.'}
      />
    );
  }

  const createIntent = async (): Promise<CreatedPaymentIntent> => {
    const { data: paymentIntent } = await createPaymentIntent({
      variables: {
        input: {
          amount: basket.tripTotal, // todo: remove it; take the value in the server
          currency: customerCurrency,
          basketId: basket.id,
        },
      },
    });
    if (paymentIntent) {
      setAmountToPay(paymentIntent.createPaymentIntent.amount);
      return paymentIntent.createPaymentIntent as CreatedPaymentIntent;
    }
    throw new Error('Failed to create intent!');
  };

  const continueToPayment = async (values: BookDepartureFormValues) => {
    const {
      selectedPaymentOptionType,
      selectedPaymentOptionDepositAmount,
      selectedPaymentOptionStartDate,
    } = values;

    const depositAmount =
      selectedPaymentOptionType === PaymentOptionType.FullBalance
        ? null
        : selectedPaymentOptionDepositAmount;

    await Promise.all([
      updateBillingDetails({
        variables: {
          input: {
            address: {
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              city: values.city,
              countryCode: values.country,
              postCode: values.postcode,
            },
          },
        },
      }),
      updateBasket({
        variables: {
          input: {
            id: basket.id,
            departureCode,
            acceptedTermsAndConditions: values.acceptedTermsAndConditions,
            joinWhatsAppGroup: values.joinWhatsAppGroup,
            selectedPaymentOption: {
              optionType: selectedPaymentOptionType as PaymentOptionType,
              depositAmount,
              paymentPlan: selectedPaymentOptionStartDate
                ? {
                    startDate: new Date(selectedPaymentOptionStartDate).toISOString(),
                  }
                : null,
            },
          },
        },
      }),
    ]);
    setIsAirwallexDropInOpen(true);
  };

  const departureDate = basket.departureDate;

  const isOver45Trip =
    orderDetailsData?.orderDetails.productLine === ProductLineSlug.Age_45_59;

  return (
    <>
      <Layout
        HeroComponent={
          <>
            <BookDepartureHeader location={orderDetailsData?.orderDetails.location} />
            {isInAgeSweetspot && isOver45Trip && <AgeSweetspotBanner />}
          </>
        }
      >
        <ResponsiveSideBySide
          mainSection={
            <Stack gap={2} position="relative">
              <StepperPill currentStep={2} totalSteps={2} />
              <PageFormTitle>Select your options</PageFormTitle>
              <Stack gap={1}>
                {basket.reservation && (
                  <ReservationTimer endTime={new Date(basket.reservation.expiresAt)} />
                )}
              </Stack>

              <Extras onChange={() => setIsAirwallexDropInOpen(false)} basket={basket} />

              <Form<BookDepartureFormValues>
                onSubmit={(values) => {
                  setAmountToPay(null);
                  return continueToPayment(values);
                }}
                keepDirtyOnReinitialize={true}
                initialValues={initialValues}
                render={({ handleSubmit, submitting, form, values }) => {
                  return (
                    <form
                      autoComplete="on"
                      id="checkout-book"
                      onSubmit={(e) => {
                        setIsAirwallexDropInOpen(false);

                        e.preventDefault();
                        void handleSubmit(e);
                      }}
                    >
                      <Stack gap={3}>
                        <Field name="joinWhatsAppGroup" type="checkbox">
                          {(props) => <JoinWhatsAppFormField {...props} />}
                        </Field>

                        <Divider sx={{ m: 0 }} />

                        {orderDetailsData?.orderDetails.promoCodesEnabled && (
                          <Stack gap={2}>
                            <Typography variant="H5">Promotional Code</Typography>
                            <PromotionalCode
                              basketId={basket.id}
                              currencyCode={currencyCode}
                              departureCode={departureCode}
                              appliedPromocodes={basket.appliedPromocodes}
                              onChange={() => setIsAirwallexDropInOpen(false)}
                            />
                          </Stack>
                        )}

                        <PaymentOptions
                          basket={basket}
                          departureDate={departureDate ?? undefined}
                          paymentPlanStartDate={values.selectedPaymentOptionStartDate}
                          form={form}
                        />

                        <Stack gap={2}>
                          <Typography variant="H5">Billing address</Typography>
                          <FormTextInput
                            name="addressLine1"
                            validate={Validator.required}
                            textInputProps={{
                              placeholder: 'Enter address...',
                              label: 'Address line 1',
                              size: 'large',
                              autoComplete: 'address-line1',
                            }}
                          />
                          <FormTextInput
                            name="addressLine2"
                            textInputProps={{
                              placeholder: 'Enter address...',
                              label: 'Address line 2',
                              size: 'large',
                              autoComplete: 'address-line2',
                            }}
                          />
                          <FormTextInput
                            name="city"
                            validate={Validator.required}
                            textInputProps={{
                              placeholder: 'Enter town/city...',
                              label: 'Town/City',
                              size: 'large',
                              autoComplete: 'address-level2',
                            }}
                          />
                          <FormTextInput
                            name="postcode"
                            textInputProps={{
                              placeholder: 'Enter code...',
                              label: 'Postcode/Zipcode',
                              autoComplete: 'postal-code',
                            }}
                            validate={composeValidators(
                              Validator.required,
                              ValidatorFactory.createMaxLength(10),
                            )}
                          />
                          <FormDropdown
                            name="country"
                            options={prioritizedCountryOptions}
                            validate={Validator.required}
                            TextInputProps={{
                              size: 'large',
                              label: 'Country',
                              placeholder: 'Select country...',
                              testid: 'select-country',
                              inputProps: {
                                autoComplete: 'nope', // 'country',
                              },
                            }}
                          />

                          {termsAndConditions && (
                            <Box mt={1}>
                              <Field
                                name="acceptedTermsAndConditions"
                                type="checkbox"
                                validate={Validator.required}
                              >
                                {(props) => (
                                  <BookingTermsAndConditionsFormField
                                    termsAndConditionsHTML={termsAndConditions}
                                    {...props}
                                  />
                                )}
                              </Field>
                            </Box>
                          )}
                        </Stack>
                        <FormSpy
                          subscription={{
                            modifiedSinceLastSubmit: true,
                            invalid: true,
                          }}
                        >
                          {(props: {
                            modifiedSinceLastSubmit: boolean;
                            invalid: boolean;
                          }) => {
                            return isAirwallexDropInOpen &&
                              !props.modifiedSinceLastSubmit &&
                              !props.invalid ? (
                              <>
                                <TotalToPay
                                  currency={customerCurrency}
                                  finalCost={amountToPay}
                                  selectedPaymentOption={basket.selectedPaymentOption}
                                />
                                <AirwallexDropInElement
                                  onSuccess={handleAirwallexSuccess}
                                  onError={handleAirwallexError}
                                  createIntent={createIntent}
                                  dropInType="payment"
                                />
                              </>
                            ) : (
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                data-testid="continue-to-payment"
                                sx={{ alignSelf: 'self-start' }}
                                loading={submitting}
                              >
                                Continue to Payment
                              </LoadingButton>
                            );
                          }}
                        </FormSpy>

                        {!!paymentError && (
                          <GenericError
                            error={
                              'Something went wrong when processing your payment. Please try again.'
                            }
                          />
                        )}
                      </Stack>
                    </form>
                  );
                }}
              />
            </Stack>
          }
          additionalSection={
            <Stack gap={3}>
              <OrderDetails
                loading={orderDetailsLoading || loadingBasket}
                orderDetails={orderDetailsData?.orderDetails}
                isFlashPerk={Boolean(basket.promotionalDiscount)}
              />
              <ToPayWidget currency={customerCurrency} basket={basket} />
              <QuoteCard
                text="Sharing a room was a wonderful thing. Flash Pack does a great job of getting to know their people beforehand and paired us based on that understanding."
                title="Our average room-mate matching score is 8.6 out of 10"
                author="Melissa Capocci"
                authorImage={melissaCapocciImage}
              />
              <SpeakToExpert />
            </Stack>
          }
        />
      </Layout>
    </>
  );
};
